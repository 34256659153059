@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";
@import "../../assets/scss/function";
$congratulations-bg: white; // Fundo preto

.congratulations-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background: $congratulations-bg;
  color: white;
  position: relative;
  overflow: hidden;
  padding: 0 20px;
  box-sizing: border-box;
}

.congratulations-content {
  text-align: center;
  max-width: 600px;
  background: rgba(0, 0, 0, 0.7);
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.title {
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 1.3rem;
  text-transform: uppercase;
  font-family: $madeoutersans_bold;

  @include breakpoint-for(desktop-small) {
    font-size: 3rem;
  }
}

.message {
  font-size: 1rem;
  margin-bottom: 20px;
  line-height: 1.6;
  font-family: $madeoutersans_light;

  @include breakpoint-for(desktop-small) {
    font-size: 1.2rem;
  }
}

.cta-message {
  font-size: 1rem;
  font-family: $madeoutersans_light;

  @include breakpoint-for(desktop-small) {
    font-size: 1.2rem;
  }

  a {
    color: red;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
