@import "../../assets/scss/_variables";
@import "../../assets/scss/_mixins";
@import "../../assets/scss/function";

.LandingPage {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 15px 20px 32px;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    padding: 32px 100px 32px;
  }

  @include breakpoint-for(desktop-extra-large) {
    padding: 32px 190px 32px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;

    @include breakpoint-for(desktop-small) {
      flex-direction: row;
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      margin: 0 auto 50px;

      @include breakpoint-for(desktop-small) {
        margin: 0 auto;
      }

      h1 {
        font-family: $madeoutersans_medium;
        font-size: pxToRem(28px);
        letter-spacing: 0;
        color: black;
        margin: 0;
        text-align: left;

        @include breakpoint-for(desktop-small) {
          font-size: pxToRem(48px);
        }

        span {
          background-color: #e40030;
          color: white;
          font-family: $madeoutersans_medium;
          letter-spacing: 0;
        }
      }

      p {
        font-family: $madeoutersans_regular;
        font-size: pxToRem(15px);
        letter-spacing: 0;
        color: black;
        margin: 50px 0 0 0;
        text-align: left;
        max-width: 668px;
        width: fit-content;

        @include breakpoint-for(desktop-small) {
          margin: 75px 0 0 0;

          font-size: pxToRem(18px);
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;

      @include breakpoint-for(desktop-small) {
        max-width: 616px;
        max-height: 619px;
      }

      .container-contact {
        align-items: center;
        background-color: #f7f7f7;
        box-sizing: border-box;
        display: flex;
        padding: 20px;
        box-sizing: border-box;
        width: 100%;
        height: 100%;

        @include breakpoint-for(desktop-small) {
          max-width: 616px;
          padding: 95px 70px 73px;
          min-height: 352px;
        }

        .form-container {
          align-items: center;
          display: flex;
          justify-content: center;
          padding: 20px;
        }

        .custom-form {
          display: flex;
          flex-direction: column;
          gap: 23px;
          min-height: 132px;
          width: 100%;

          .form-row {
            display: flex;
            flex-direction: column;
            gap: 17px;
            height: 100%;
            justify-content: space-between;
            width: 100%;

            .form-group {
              display: flex;
              flex-direction: column;
              flex: 1;
              min-width: 45%;
              position: relative;

              &.error {
                input,
                textarea {
                  border-color: #e40030;
                }
              }

              input {
                border: 1px solid #000000;
                border-radius: 0;
                box-sizing: border-box;
                font-family: $madeoutersans_light;
                font-size: 15px;
                height: 58px;
                outline: none;
                padding: 10px;
                transition: border-color 0.3s;
                width: 100%;

                &::placeholder {
                  color: #848484;
                }

                &:focus {
                  border-color: #000;
                }
              }

              .error {
                bottom: -14px;
                color: red;
                font-size: 12px;
                position: absolute;
                text-align: left;
              }
            }

            textarea {
              border: 1px solid #000000;
              box-sizing: border-box;
              font-family: $madeoutersans_light;
              font-size: 14px;
              height: 132px;
              outline: none;
              padding: 10px;
              resize: none;
              transition: border-color 0.3s;
              width: 100%;

              &::placeholder {
                color: #848484;
              }

              &:focus {
                border-color: #000;
              }
            }

            .textarea-group {
              flex: 2;
              height: 100%;
              max-height: 132px;
            }

            .btn-enviar {
              align-self: flex-end;
              border: none;
              background-color: #000;
              color: #fff;
              cursor: pointer;
              width: 100%;
              font-family: $madeoutersans_bold;
              padding: 17px 66px;
              font-size: pxToRem(15px);
              transition: background-color 0.3s;

              @include breakpoint-for(desktop-small) {
                font-size: pxToRem(20px);
              }

              &:hover {
                background-color: #333;
              }
            }
          }

          .text-container {
            p {
              font-family: $madeoutersans_regular;
              font-size: pxToRem(17px);
              letter-spacing: 0;
              color: #000;
              margin: 0;
              text-align: left;

              @include breakpoint-for(desktop-small) {
                font-size: pxToRem(28px);
              }

              span {
                background-color: #e40030;
                color: white;
                font-family: $madeoutersans_medium;
              }
            }
          }
        }
      }
    }
  }

  .learning-section {
    background-color: transparent;
    box-sizing: border-box;
    color: #fff;
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    position: relative;
    width: 100%;

    @include breakpoint-for(tablet-portrait) {
      margin: 0;
    }

    .text-flutuant {
      color: #555555;
      font-family: $display_playfair;
      font-size: 30px;
      left: -5%;
      position: absolute;
      top: 32%;
      transform: matrix(0, -1, 1, 0, 0, 0);
      visibility: hidden;

      @include breakpoint-for(tablet-portrait) {
        visibility: visible;
      }
    }

    .dot {
      align-items: center;
      background-color: black;
      border-radius: 50%;
      display: flex;
      height: 20px;
      justify-content: center;
      margin: 10px;
      width: 20px;

      position: relative;

      img {
        position: absolute;
        top: 0;
        right: 50%;
      }
    }

    &__item-learning-box {
      color: black;
      max-width: 520px;
      padding: 0 10px;
      position: relative;

      @include breakpoint-for(tablet-portrait) {
        padding: 0 50px;
      }
    }

    &__title {
      color: #000000;
      font-family: $roboto_bold;
      font-size: 20px;
      margin: 0 0 20px 0;
      position: relative;
      text-align: left;
      text-transform: uppercase;
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__item {
      border-top: 1px solid #000000;
      padding: 30px 0;
      width: 100%;

      &:nth-child(3) {
        border-bottom: 1px solid #000000;
      }

      &--left {
        align-self: flex-start;
        display: flex;
        justify-content: space-between;
        text-align: left;
        position: relative;
        flex-direction: column;

        @include breakpoint-for(desktop-small) {
          flex-direction: row;
        }

        .learning-section__item-description {
          max-width: 485px;
        }

        .learning-section__item-title {
          position: relative;

          @include breakpoint-for(desktop-small) {
            right: 40px;
          }

          span {
            margin-left: 40px;
          }
        }

        img {
          width: 200px;

          @include breakpoint-for(desktop-small) {
            width: min-content;
          }
        }

        .dot {
          left: 90%;
          position: absolute;
          bottom: 0;

          @include breakpoint-for(desktop-small) {
            left: 98%;
          }
        }
      }

      &--right {
        align-items: flex-end;
        display: flex;
        justify-content: space-between;
        text-align: left;
        position: relative;
        flex-direction: column;

        @include breakpoint-for(desktop-small) {
          flex-direction: row-reverse;
        }

        .learning-section__item-description {
          max-width: 485px;
          text-align: left;
        }

        .learning-section__item-title {
          position: relative;
          right: 0;

          @include breakpoint-for(desktop-small) {
            right: 40px;
          }

          span {
            margin-left: 40px;
          }
        }

        img {
          width: 200px;
        }

        .dot {
          position: absolute;
          bottom: 0;
          right: 90%;

          @include breakpoint-for(desktop-small) {
            right: 98%;
          }
        }
      }
    }

    &__item-title {
      font-family: $madeoutersans_regular;
      font-size: 30px;
      margin: 0;

      span {
        font-family: $times_new_roman_bold_italic;
        font-style: italic;
      }

      @include breakpoint-for(tablet-portrait) {
        font-size: 45px;
      }
    }

    &__item-description {
      color: #000000;
      font-family: $madeoutersans_light;
      font-size: pxToRem(18px);
      // line-height: 35px;
      margin-top: 40px;
    }
  }

  .duration-section {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin: 90px 0;
    gap: 20px;
    flex-direction: column;

    @include breakpoint-for(desktop-small) {
      padding: 0 20px;
      flex-direction: row;
    }

    .duration {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      border-radius: 7px;
      background-color: black;
      padding: 60px 40px;
      box-sizing: border-box;
      gap: 27px;
      width: 100%;

      @include breakpoint-for(desktop-small) {
        max-width: 450px;
        width: 33%;
      }

      .duration-title {
        background-color: white;
        color: black;
        font-family: $madeoutersans_bold;
        font-size: pxToRem(28px);
        margin: 0;
        padding: 0 4px;
        text-align: left;
      }

      .duration-content {
        color: white;
        font-family: $madeoutersans_regular;
        font-size: pxToRem(18px);
        font-weight: 100;
        line-height: 28px;
        margin: 0;
        text-align: left;
      }
    }
  }
}

.contact-form {
  align-items: center;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 15px 20px 32px;
  width: 100%;

  @include breakpoint-for(desktop-small) {
    padding: 32px 100px 70px;
  }

  @include breakpoint-for(desktop-extra-large) {
    padding: 32px 190px 70px;
  }

  .contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #f7f7f7;
    flex-direction: column;

    @include breakpoint-for(desktop-small) {
      flex-direction: row;
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      margin: 0 auto;

      h2 {
        font-family: $madeoutersans_regular;
        font-size: pxToRem(23px);
        letter-spacing: 0;
        font-weight: 400;
        color: black;
        margin: 0;
        text-align: left;

        @include breakpoint-for(desktop-small) {
          font-size: pxToRem(35px);
        }
      }

      p {
        font-family: $madeoutersans_medium;
        font-size: pxToRem(25px);
        letter-spacing: 0;
        color: black;
        margin: 30px 0 30px 0;
        text-align: left;
        max-width: 668px;
        width: fit-content;

        @include breakpoint-for(desktop-small) {
          margin: 75px 0 0 0;
          font-size: pxToRem(32px);
        }

        span {
          background-color: #e40030;
          color: white;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      max-width: 583px;
      max-height: 619px;

      .container-contact {
        align-items: center;
        box-sizing: border-box;
        display: flex;
        box-sizing: border-box;
        width: 100%;
        max-width: 583px;
        height: 100%;

        @include breakpoint-for(tablet) {
          min-height: 352px;
        }

        .form-container {
          align-items: center;
          display: flex;
          justify-content: center;
          padding: 20px;
        }

        .custom-form {
          display: flex;
          flex-direction: column;
          gap: 23px;
          min-height: 132px;
          width: 100%;

          .form-row {
            display: flex;
            flex-direction: column;
            gap: 17px;
            height: 100%;
            justify-content: space-between;
            width: 100%;

            .form-group {
              display: flex;
              flex-direction: column;
              flex: 1;
              min-width: 45%;
              position: relative;

              &.error {
                input,
                textarea {
                  border-color: #e40030;
                }
              }

              input {
                border: 1px solid #000000;
                border-radius: 0;
                box-sizing: border-box;
                font-family: $madeoutersans_light;
                font-size: 15px;
                height: 58px;
                outline: none;
                padding: 10px;
                transition: border-color 0.3s;
                width: 100%;

                &::placeholder {
                  color: #848484;
                }

                &:focus {
                  border-color: #000;
                }
              }

              .error {
                bottom: -14px;
                color: red;
                font-size: 12px;
                position: absolute;
                text-align: left;
              }
            }

            textarea {
              border: 1px solid #000000;
              box-sizing: border-box;
              font-family: $madeoutersans_light;
              font-size: 14px;
              height: 132px;
              outline: none;
              padding: 10px;
              resize: none;
              transition: border-color 0.3s;
              width: 100%;

              &::placeholder {
                color: #848484;
              }

              &:focus {
                border-color: #000;
              }
            }

            .textarea-group {
              flex: 2;
              height: 100%;
              max-height: 132px;
            }

            .btn-enviar {
              align-self: flex-end;
              border: none;
              background-color: #000;
              color: #fff;
              cursor: pointer;
              width: 100%;
              font-family: $madeoutersans_bold;
              padding: 17px 66px;
              font-size: pxToRem(15px);
              transition: background-color 0.3s;

              @include breakpoint-for(desktop-small) {
                font-size: pxToRem(20px);
              }

              &:hover {
                background-color: #333;
              }
            }
          }
        }
      }
    }
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Garantir que o popup fique em cima de outros elementos */

  /* Popup */
  .popup {
    background-color: white;
    padding: 30px 40px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    position: relative;

    .close-popup {
      color: #333;
      cursor: pointer;
      display: flex;
      font-size: 24px;
      justify-content: flex-end;
      width: 100%;
      position: absolute;
      top: 10px; 
      right: 0;

      button {
        background-color: transparent;
        border: unset;
        color: #333;
        font-size: 24px;
        cursor: pointer;
        width: 40px;

        &:hover {
          color: #e40030;
        }
      }
    }

    h2 {
      margin: 10px 0 0 0;
      font-size: pxToRem(21px);
      text-align: left;
      color: #e40030;
      font-family: $madeoutersans_regular;
      text-transform: uppercase;

      span {
        background-color: #e40030;
        color: white;
        padding-left: 5px;
        font-family: $madeoutersans_regular;
      }
    }

    p {
      margin-top: 40px;
      font-size: pxToRem(15px);
      text-align: left;
      font-family: $madeoutersans_regular;
    }
  }
}
